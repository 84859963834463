<template>
  <div role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div :class="settings.classes.input">
      <textarea ref="input" class="form-control" :class="{ 'is-invalid': !isValid }" v-model="state" :name="name" :placeholder="$t(cplaceholder)" v-bind="$attrs" v-on="listeners" @input="onInput($event)" @change="onChange($event)" />
      <Label v-if="helper" :class="settings.classes.input + settings.classes.label">{{ $t(helper) }}</Label>
      <template v-if="!isValid">
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback" style="display: block;">{{ error.message }}</div>
      </template>
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'ATextArea',
  inheritAttrs: true,
  watch: {
    value(val) {
      this.state = val
    }
  },
  props: {
    value: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    helper: {
      type: String
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    name_clean() {
      return this.name.split('.')[0]
    },
    listeners() {
      const { input, change, ...listeners } = this.$listeners
      return listeners
    }
  },
  data() {
    return {
      state: this.value,
      settings: { ...CONFIG },
      cplaceholder: this.$attrs.placeholder || this.placeholder || this.label
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
  },
  methods: {
    onInput(e) {
      this.state = e.target.value
      this.$emit('input', this.state, e)

      clearTimeout(this.syncTimeout)
      this.syncTimeout = setTimeout(
        () => {
          this.$emit('update:value', this.state, e)
        } /*,
        this.lazy !== false ? this.lazy : 0*/
      )
    },
    onChange(e) {
      this.state = e.target.value
      this.$emit('change', this.state, e)
      this.$emit('update:value', this.state, e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style';

.control-helper {
  color: $input-placeholder-color;
  font-size: 0.9em;
  padding-top: 7px;
}
</style>
